import styled from 'styled-components';
import { useState, useContext, useMemo } from 'react';
import Typography from 'shared/components/Typography';
import getColorTokens from 'core/utils/getColorTokens';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import TrashIcon from 'shared/components/TrashIcon';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import Store from './dashboardStore';
import { ActionTypes } from './dashboardReducer';
import SetMediaDescriptionTexts from './SetMediaDescriptionTexts';
import SetMediaInput from './SetMediaInput';
import MediaErrorSpan from './MediaErrorSpan';

const SetMediaContentStyles = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 24px;
  padding-top: 32px;
  & .media-loader-section {
    width: 100%;
    height: 140px;
    background: #ffffff;
    border: 2px dashed #e5e5e5;
    border-radius: 4px;
    margin-top: 8px;
    overflow-x: hidden;
    & .badge {
      box-sizing: border-box;
      width: 80px;
      height: 24px;
      background-color: ${getColorTokens('alias-button-primary')};
      color: white;
      text-align: center;
      padding: 5px 15px;
      border-radius: 4px;
    }
  }
  & .mt-7 {
    margin-top: 7px;
  }
  & .description-cont {
    outline: none;
    border: 1px solid #667085;
    border-radius: 4px;
    width: 90%;
    height: 66px;
    resize: none;
    padding: 5px 15px;
    font-family: 'Noto Sans';
  }
`;

interface Props {
  title: string;
  contentType: 'video' | 'image';
  handleChange: Function;
  state: {
    name: string;
    link: string;
    title: string;
    description: string;
  };
}
export default function SetMediaContentComponent({
  contentType,
  title,
  state,
  handleChange,
}: Props) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [files, setFiles] = useState<ImageListType>([]);
  const { state: storeState, dispatch } = useContext(Store);
  const maxFilesNumber = 1;
  const maxImageSize = 20971520;
  const imageFormats = ['png', 'jpg'];

  const onChangeImage = (imageList: ImageListType) => {
    const { dataUrl } = imageList[0];
    setFiles(imageList);

    dispatch({
      type: ActionTypes.UPDATE_IMAGE,
      payload: {
        value: {
          ...storeState[state.name],
          imageUrl: dataUrl,
          image: imageList[0].file,
          updatedAt: new Date(),
        },
        name: state.name,
      },
    });
  };

  const onRemoveImage = () => {
    setFiles([]);
    dispatch({
      type: ActionTypes.UPDATE_IMAGE,
      payload: {
        value: {
          ...storeState[state.name],
          imageUrl: '',
          image: undefined,
        },
        name: state.name,
      },
    });
    // TODO: make next line available if Design team requires the modal
    // setShowModal(false);
  };
  const showEmailValidationError = useMemo<boolean>(
    () => !/^(https?:\/\/)[\w-]+(\.[\w-]+)+([/\w.,@?^=%&:~+#-]*)?$/g.test(state.link),
    [state.link],
  );
  return (
    <ImageUploading
      value={files}
      maxNumber={maxFilesNumber}
      onChange={onChangeImage}
      acceptType={imageFormats}
      maxFileSize={maxImageSize}
      dataURLKey="dataUrl"
    >
      {({ onImageUpload, errors }) => (
        <SetMediaContentStyles>
          <Typography variant="subtitle-04-semibold">{title}</Typography>
          <div className="media-loader-section total-center">
            {showModal && (
              <ConfirmationModal
                message="¿Quieres eliminar esta imagen?"
                confirmationAction={onRemoveImage}
                declineAction={() => setShowModal(false)}
              />
            )}
            {!files.length ? (
              <button
                type="button"
                className="badge total-center cursor-pointer"
                onClick={onImageUpload}
              >
                Examinar
              </button>
            ) : (
              <div className="prev-container total-center">
                <img
                  className="prev-image"
                  src={files[0].dataUrl}
                  alt="preview"
                />
                <div className="prev-middle">
                  <button
                    className="prev-button"
                    onClick={onRemoveImage}
                    type="button"
                  >
                    <TrashIcon />
                  </button>
                </div>
              </div>
            )}
          </div>
          <SetMediaDescriptionTexts contentType="image" />
          {errors?.maxFileSize && (
            <MediaErrorSpan error="El peso máximo ha sido excedido, intenta subir una nueva imagen con el peso permitido" />
          )}
          {errors?.acceptType && (
            <MediaErrorSpan error="El formato de la imagen no corresponde" />
          )}
          {contentType === 'image' && (
            <>
              <SetMediaInput
                label="Enlace relacionado:"
                placeholder="https://text-example.com"
                name="link"
                value={state.link}
                onChange={(event) => handleChange(event, state.name)}
              />
              {showEmailValidationError && (
              <div>
                <MediaErrorSpan error="La URL tiene un formato inválido" />
                <MediaErrorSpan variant="text-05-medium" error="Ejemplo: https://test.com/valor/valor" />
              </div>
              )}
              <SetMediaInput
                label="Título:"
                placeholder="Escribe aquí..."
                name="title"
                value={state.title}
                onChange={(event) => handleChange(event, state.name)}
              />
              <div className="mt-1">
                <Typography variant="text-04-medium" color="0">
                  Descripción
                </Typography>
                <textarea
                  placeholder="Escribe aquí..."
                  className="description-cont mt-7"
                  name="description"
                  value={state.description}
                  onChange={(event) => handleChange(event, state.name)}
                />
              </div>
            </>
          )}
        </SetMediaContentStyles>
      )}
    </ImageUploading>
  );
}
