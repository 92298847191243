import DashboardSidebar from 'features/admin/dashboard/DashboardSidebar';
import { PropsWithChildren } from 'react';
import Typography from 'shared/components/Typography';
import styled from 'styled-components';

type SideBarProps = PropsWithChildren<React.ComponentProps<typeof DashboardSidebar>> & {
  title: string;
};

const SideBar = ({ children, activeView, title }: PropsWithChildren<SideBarProps>) => (
  <Container>
    <DashboardSidebar activeView={activeView} />
    <ViewContainer>
      <ViewHeader>
        <Typography variant="subtitle-04-semibold" className="sec-title">
          ¡Bienvenido!
        </Typography>
        <Typography variant="text-04-medium" className="sec-des">
          {title}
        </Typography>
      </ViewHeader>
      {children}
    </ViewContainer>
  </Container>
);

export default SideBar;

const Container = styled.div`
  box-sizing: border-box;
  height: 100vh;
  display: flex;
`;

const ViewHeader = styled.div`
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  padding: 24px 32px;
`;

const ViewContainer = styled.div`
  overflow: hidden;
  width: 100vw;
  height: 100%;
`;
