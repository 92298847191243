const routeList = {
  HOME: '/',
  NOT_FOUND: '*',
  ADMIN: '/admin',
  DASHBOARD: '/dashboard',
  CONTENT_DESIGN: '/admin/diseno-contenido',
  USERS: '/admin/usuarios',
  DOWNLOAD_APP_VIEW: '/download',
  LOGIN: '/admin/login',
};

export default routeList;
