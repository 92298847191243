const ThumbsUp = () => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0.576172C9.60481 0.576172 9.24669 0.808905 9.08619 1.17003L5.35013 9.57617H3C2.20435 9.57617 1.44129 9.89224 0.87868 10.4549C0.316071 11.0175 0 11.7805 0 12.5762V19.5762C0 20.3718 0.316071 21.1349 0.87868 21.6975C1.44129 22.2601 2.20435 22.5762 3 22.5762H17.2748C17.9961 22.5829 18.6958 22.3294 19.2456 21.8621C19.7966 21.3937 20.1599 20.7419 20.2686 20.0269L21.6487 11.0262C21.7139 10.5963 21.6849 10.1573 21.5637 9.73961C21.4424 9.32197 21.2318 8.93568 20.9465 8.60751C20.6611 8.27934 20.3078 8.01713 19.911 7.83906C19.5162 7.66184 19.0876 7.57217 18.6549 7.57617H14V4.57617C14 3.51531 13.5786 2.49789 12.8284 1.74774C12.0783 0.997599 11.0609 0.576172 10 0.576172ZM10.6078 2.67077L7 10.7884V20.5762H17.2913C17.5325 20.5789 17.7665 20.4944 17.9503 20.3382C18.134 20.182 18.2551 19.9647 18.2913 19.7262L19.6713 10.7261C19.693 10.5829 19.6834 10.4363 19.643 10.2972C19.6026 10.158 19.5324 10.0293 19.4373 9.91986C19.3421 9.81047 19.2244 9.72307 19.0921 9.66372C18.9599 9.60436 18.8163 9.57447 18.6713 9.57611L18.66 9.57624L13 9.57617C12.4477 9.57617 12 9.12846 12 8.57617V4.57617C12 4.04574 11.7893 3.53703 11.4142 3.16196C11.1864 2.93411 10.9092 2.76692 10.6078 2.67077ZM5 11.5762V20.5762H3C2.73478 20.5762 2.48043 20.4708 2.29289 20.2833C2.10536 20.0957 2 19.8414 2 19.5762V12.5762C2 12.311 2.10536 12.0566 2.29289 11.8691C2.48043 11.6815 2.73478 11.5762 3 11.5762H5Z"
      fill="#2D3648"
    />
  </svg>
);

export default ThumbsUp;
