import { User } from 'services/index';

interface StateModel {
  isLoading: boolean;
  users: User[] | [];
  showTableConfig: boolean;
  filterValue: number;
  currentPage: number;
  totalPages: number;
  totalItems: number;
}

export const initialState: StateModel = {
  isLoading: false,
  users: [],
  showTableConfig: false,
  filterValue: 50,
  currentPage: 1,
  totalPages: 0,
  totalItems: 0,
};

export enum ActionTypes {
  SET_USERS = 'SET_USERS',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_FILTER_VALUE = 'SET_FILTER_VALUE',
  SET_SHOW_TABLE_CONFIG = 'SET_SHOW_TABLE_CONFIG',
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  SET_TOTAL_PAGES = 'SET_TOTAL_PAGES',
  SET_TOTAL_AND_CURRENT = 'SET_TOTAL_AND_CURRENT',
}

interface Action {
  type: ActionTypes;
  payload?: any;
}

const usersViewReducer = (state: StateModel, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_USERS:
      return {
        ...state,
        users: [...payload],
      };
    case ActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case ActionTypes.SET_FILTER_VALUE:
      return {
        ...state,
        filterValue: payload,
      };
    case ActionTypes.SET_SHOW_TABLE_CONFIG:
      return {
        ...state,
        showTableConfig: payload,
      };
    case ActionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload,
      };
    case ActionTypes.SET_TOTAL_PAGES:
      return {
        ...state,
        totalPages: payload,
      };
    case ActionTypes.SET_TOTAL_AND_CURRENT:
      return {
        ...state,
        currentPage: payload.currentPage,
        totalPages: payload.totalPages,
        totalItems: payload.totalItems,
      };
    default:
      throw new Error();
  }
};

export default usersViewReducer;
