import { useContext } from 'react';
import styled from 'styled-components';
import getColorFamilies from 'core/utils/getColorTokens';
import ArrowDown from 'shared/components/svgIcons/ArrowDown';
import VideoPlayer from 'shared/components/VideoPlayer';
import { Element, Link } from 'react-scroll';
import Header from './Header';
import Banners from './Banners';
import Store from '../dashboardStore';

const CaptiveHome = () => {
  const { state } = useContext(Store);

  const {
    video,
  } = state;

  const scrollDuration = 3200;

  return (
    <Container>
      <Header />
      <Content>

        <SrollLinkButton
          activeClass="active"
          to="scroll-reference"
          duration={scrollDuration}
          smooth="easeInOutQuart"
          spy
        >
          Conéctate ahora
          {'  '}
          <span>
            <ArrowDown />
          </span>
        </SrollLinkButton>

        <Banners />

        <VideoPlayer videoUrl={video?.videoUrl} />

        <ScrollElementFooter name="scroll-reference">
          <div className="flex-item">
            <button type="button" className="button-get-app " onClick={() => {}}>
              Conéctate
            </button>
          </div>
        </ScrollElementFooter>
      </Content>
    </Container>
  );
};

export default CaptiveHome;

const Container = styled.div`
  max-width: 420px;
  width: 100%;
  height: auto;
  overflow-x: hidden;
  margin: 0 auto;
`;

const Content = styled.div`
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 88%;
`;

const SrollLinkButton = styled(Link)`
  padding: 12px;
  height: auto;
  font-size: 14px;
  border: 1px solid ${getColorFamilies('alias-button-primary')};
  color: ${getColorFamilies('alias-button-primary')};
  background-color:transparent;
  border-radius: 12px;
  & span {
    margin-left: 8px;
  }
`;

const ScrollElementFooter = styled(Element)`
width: 100%;
margin-top: 24px;
button{
  width: 100%;
  padding: 16px 12px;
}
`;
