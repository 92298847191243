import 'core/styles/phoneDevice.css';
import 'core/styles/utils.css';
import CaptiveHome from 'features/admin/dashboard/CaptiveHome/CaptiveHome';

const PhoneDevice = () => (
  <div className="div-body">
    <section className="phone-body">
      <div className="phone-inner">
        <article>
          <CaptiveHome />
        </article>
      </div>
    </section>
  </div>
);

export default PhoneDevice;
