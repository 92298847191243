const EmailIcon = () => (
  <svg
    width="23"
    height="21"
    viewBox="0 0 27 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25293 6.62891C1.25293 5.52434 2.14836 4.62891 3.25293 4.62891H23.2529C24.3575 4.62891 25.2529 5.52434 25.2529 6.62891V19.6289C25.2529 20.7335 24.3575 21.6289 23.2529 21.6289H3.25293C2.14836 21.6289 1.25293 20.7335 1.25293 19.6289V6.62891Z"
      stroke="#667085"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M2.67424 5.93177C2.17002 5.46957 2.49702 4.62891 3.18103 4.62891H23.3248C24.0088 4.62891 24.3358 5.46957 23.8316 5.93177L15.2801 13.7707C14.1331 14.822 12.3727 14.822 11.2258 13.7707L2.67424 5.93177Z"
      stroke="#667085"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

export default EmailIcon;
