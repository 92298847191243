import ROUTES from 'routes/routes';
import { Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const RedirectRoute = ({ to }: { to: string }) => {
  const { getIsLoggedIn } = useAuth();

  const isLoggedIn = getIsLoggedIn();

  const route = isLoggedIn ? to : ROUTES.LOGIN;

  return <Navigate to={route} />;
};

export default RedirectRoute;
