import styled from 'styled-components';
import { useState } from 'react';
import Typography from 'shared/components/Typography';
import TrashIcon from 'shared/components/TrashIcon';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import getColorTokens from 'core/utils/getColorTokens';
import SetMediaDescriptionTexts from './SetMediaDescriptionTexts';
import { BurySpinner } from '../../../shared/BurySpinner';

const SetMediaContentStyles = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 24px;
  padding-top: 32px;
  & .media-loader-section {
    width: 100%;
    height: 140px;
    background: #ffffff;
    border: 2px dashed #e5e5e5;
    border-radius: 4px;
    margin-top: 8px;
    & .badge {
      box-sizing: border-box;
      width: 80px;
      height: 24px;
      background-color: ${getColorTokens('alias-button-primary')};
      color: white;
      text-align: center;
      padding: 5px 15px;
      border-radius: 4px;
    }
  }
  & .mt-7 {
    margin-top: 7px;
  }
  & .description-cont {
    outline: none;
    border: 1px solid #667085;
    border-radius: 4px;
    width: 100%;
    height: 66px;
    resize: none;
  }
`;

interface Props {
  title: string;
  setVideoFile: Function;
  setVideoUrl: Function;
}
export default function SetVideoContentComponent({
  title,
  setVideoFile,
  setVideoUrl,
}: Props) {
  const [videoPreview, setVideoPreview] = useState<any>();
  const [showDeleteVideoModal, setShowDeleteVideoModal] = useState(false);
  const loadVideo = () => {
    const input = document.createElement('input') as HTMLInputElement;
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    canvas.width = 320;
    canvas.height = 320;
    input.type = 'file';
    input.accept = 'video/*';
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file as Blob);
      fileReader.onload = () => setVideoUrl(fileReader.result);
      BurySpinner.show();
      setVideoFile(file);
      const ctx = canvas.getContext('2d');
      const video = document.createElement('video');
      video.src = URL.createObjectURL(
        (e.target as HTMLInputElement).files?.[0] as Blob,
      );
      video.muted = true;
      video.autoplay = true;
      video.onloadedmetadata = () => {
        video.currentTime = video.duration / 2;
      };
      video.onloadeddata = () => {
        BurySpinner.hide();
        ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
        setVideoPreview(canvas.toDataURL('image/jpeg'));
      };
    };
    input.click();
  };
  const handleDeleteVideo = () => {
    setVideoFile(null);
    setVideoUrl('');
    setVideoPreview('');
    setShowDeleteVideoModal(false);
  };
  return (
    <SetMediaContentStyles>
      {showDeleteVideoModal && (
        <ConfirmationModal
          message="¿Quieres eliminar este video?"
          confirmationAction={handleDeleteVideo}
          declineAction={() => setShowDeleteVideoModal(false)}
        />
      )}
      <Typography variant="subtitle-04-semibold">{title}</Typography>
      <div className="media-loader-section total-center">
        {videoPreview ? (
          <div className="prev-container total-center">
            <img className="prev-image" src={videoPreview} alt="preview" />
            <div className="prev-middle">
              <button
                className="prev-button"
                onClick={() => setShowDeleteVideoModal(true)}
                type="button"
              >
                <TrashIcon />
              </button>
            </div>
          </div>
        ) : (
          <button
            type="button"
            className="badge total-center cursor-pointer"
            onClick={loadVideo}
          >
            Examinar
          </button>
        )}
      </div>
      <SetMediaDescriptionTexts contentType="video" />
    </SetMediaContentStyles>
  );
}
