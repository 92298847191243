// import Logo from 'components/svgIcons/Logo';

import styled from 'styled-components';
import appImage from 'assets/icons/wifi.svg';
import Typography from 'shared/components/Typography';
import Logo from 'shared/components/svgIcons/Logo';

const Header = () => (
  <Container>
    <LogoContainer>
      <Logo />
      <AvatarIcon>
        <img src={appImage} alt="wifi" />
      </AvatarIcon>
    </LogoContainer>
    <HeadingText variant="title-02-bold" textAlign="center">
      Accede a internet
    </HeadingText>

    <Title variant="title-04-bold" textAlign="center">
      GRATIS
    </Title>

    <Typography variant="subtitle-06-semibold" textAlign="center">
      Disfruta de tu conexión a internet con solo unos clics.
    </Typography>
  </Container>
);

export default Header;

const HeadingText = styled(Typography)`
color: #002A77;
font-size: 24px;
`;

const Title = styled(Typography)`
color: #ED1C2A;
`;

const Container = styled.div`
  text-align: center;
  position: relative;
  margin-bottom: 24px;
  
`;

const avatarRadio = 60;
const AvatarIcon = styled.div`
  background-color: white;
  border-radius: 50%;
  height: ${avatarRadio * 2}px;
  width:   ${avatarRadio * 2}px;
  position: absolute;
  bottom: -${avatarRadio}px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 10px 0px rgba(102, 112, 133, 0.10);
  & img {
    width: 84px;
  }
`;

const LogoContainer = styled.div`
background-color: #002A77;
height: 200px;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
margin-bottom: ${avatarRadio}px;
position: sticky;
top: -40px;
z-index: 10;

`;
