// @ts-nocheck
import styled from 'styled-components';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Typography from 'shared/components/Typography';

const TableConfigBarStyles = styled.div`
  box-sizing: border-box;
  padding-left: 24px;
  padding-top: 70px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  position: fixed;
  height: 100%;
  width: 350px;
  background-color: white;
  top: 0;
  right: 0;
  z-index: 10;
  & .close-icon {
    position: absolute;
    top: 12px;
    right: 12px;
  }
  & .radio-input{
    margin-top: 20px;
    display: flex;
    gap: 10px;
  }
  & .radio-input input[type="radio"]{
    width: 20px;
    height: 20px;
  }
  & .number-text{
    margin: 24px 0 12px 0;
  }
`;

interface Props {
  setCloseStateF: { (state: boolean):void },
  filterValue: number,
  setFilterValue: { (filter: number): void }
}
export default function TableConfigBar(
  { setCloseStateF, filterValue, setFilterValue }: Props,
) {
  const values = [50, 100, 200];
  return (
    <TableConfigBarStyles>
      <AiOutlineCloseCircle onClick={() => setCloseStateF(false)} className="close-icon cursor-pointer" size="24px" />
      <Typography variant="subtitle-04-semibold">Configuración de la tabla</Typography>
      <Typography variant="text-03-regular" className="number-text">Número de registros a mostrar</Typography>
      {
            values.map((val) => (
              <div key={val} className="radio-input">
                <input
                  onChange={() => setFilterValue(val)}
                  id={val}
                  type="radio"
                  value={val}
                  checked={filterValue === val}
                />
                <Typography className="text-left" variant="text-03-regular">{val}</Typography>
              </div>
            ))
        }
    </TableConfigBarStyles>
  );
}
