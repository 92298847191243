const cardSeedData = [
  {
    imageUrl: 'https://picsum.photos/380/240',
    url: 'https://puller.mx',
    cardTitle: 'Disfruta de las actividades culturales',
    cardText:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo explicabo hic reiciendis ratione eaque commodi magnam voluptatibus ducimus. Hic illum praesentium mollitia nam quasi amet ea nobis corrupti ipsum quae.',
  },
  {
    imageUrl: 'https://picsum.photos/380/240',
    url: 'https://puller.mx',
    cardTitle: 'Julio y Agosto',
    cardText:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo explicabo hic reiciendis ratione eaque commodi magnam voluptatibus ducimus. Hic illum praesentium mollitia nam quasi amet ea nobis corrupti ipsum quae.',
  },
  {
    imageUrl: 'https://picsum.photos/380/240',
    url: 'https://puller.mx',
    cardTitle: 'Octubre',
    cardText:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo explicabo hic reiciendis ratione eaque commodi magnam voluptatibus ducimus. Hic illum praesentium mollitia nam quasi amet ea nobis corrupti ipsum quae.',
  },
];

export default cardSeedData;
