const ArrowDown = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 0.75C6.75 0.335786 6.41421 0 6 0C5.58579 0 5.25 0.335786 5.25 0.75V9.43934L1.28033 5.46967C0.987437 5.17678 0.512563 5.17678 0.21967 5.46967C-0.0732233 5.76256 -0.0732233 6.23744 0.21967 6.53033L5.46967 11.7803C5.76256 12.0732 6.23744 12.0732 6.53033 11.7803L11.7803 6.53033C12.0732 6.23744 12.0732 5.76256 11.7803 5.46967C11.4874 5.17678 11.0126 5.17678 10.7197 5.46967L6.75 9.43934V0.75Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowDown;
