const formatDate = (inputDate: number | string | null) => {
  if (!inputDate) {
    return '--/--/----';
  }
  const dateObj = new Date(inputDate);
  const day = dateObj.getDate().toString().padStart(2, '0');
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();
  const stringMonth = month.toString().padStart(2, '0');
  return `${year}/${stringMonth}/${day}`;
};

export default formatDate;
