import styled from 'styled-components';
import getTokenValues from 'core/utils/getTokensValues';

const ButtonDiv = styled.div`
display: flex;
justify-content: center;
`;

const StyledButton = styled.button`
background: ${(props) => (props.disabled ? 'gray' : getTokenValues('700'))};
color: white;
width: 70%;
border-radius: 8px;
padding: 20px;
border: 0px;
font-weight: 400;
font-family: 'Noto Sans';
`;

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  disabled?: boolean
}

const Button = ({ children, disabled, ...rest } : ButtonProps) => (
  <ButtonDiv>
    <StyledButton disabled={disabled} {...rest}>
      { children }
    </StyledButton>
  </ButtonDiv>
);

export default Button;
