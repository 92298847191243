import styled from 'styled-components';
import UIButton from 'core/ui/UIButton';
import { AiOutlineSetting } from 'react-icons/ai';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import {
  useEffect,
  useMemo,
  useCallback,
  useReducer,
} from 'react';
import ReactPaginate from 'react-paginate';
import { listUserPaginate, userExportExcel } from 'services/index';
import TableSkeleton from 'core/ui/TableSkeleton';
import formatDate from 'core/utils/formatDate';
import UIBadge from 'core/ui/UIBadge';
import SideBar from 'shared/template/SideBar';
import ROUTES from 'routes/routes';
import { BurySpinner } from 'shared/BurySpinner';
import UITable from 'core/ui/UITable';
import usersViewReducer, { ActionTypes, initialState } from './usersViewReducer';
import 'core/styles/PaginatorStyles.css';
import columns from './UsersTableColumns';
import TableConfigBar from './TableConfigBar';

const UsersViewStyles = styled.div`
  box-sizing: border-box;
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 55px;
  & .actions{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 18px;
    margin-bottom: 22px;
  }
  & .table-footer-data{
    margin-top: 34px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;
export default function UsersView() {
  const [state, dispatch] = useReducer(usersViewReducer, initialState);
  const {
    isLoading,
    users,
    showTableConfig,
    filterValue,
    totalPages,
    currentPage,
    totalItems,
  } = state;

  const canShowElements = useMemo<boolean>(() => !!users.length && !isLoading, [isLoading, users]);

  const setFilterValue = (value: number) => dispatch({
    type: ActionTypes.SET_FILTER_VALUE,
    payload: value,
  });

  const setShowTableConfig = (value: boolean) => dispatch({
    type: ActionTypes.SET_SHOW_TABLE_CONFIG,
    payload: value,
  });

  const getUsers = useCallback(() => {
    const page = currentPage;
    const limit = filterValue;
    dispatch({ type: ActionTypes.SET_IS_LOADING, payload: true });
    listUserPaginate({ page, limit })
      .then((response) => {
        const data = response?.data || {};
        dispatch({
          type: ActionTypes.SET_TOTAL_AND_CURRENT,
          payload: {
            totalPages: data?.totalPages,
            currentPage: data?.currentPage,
            totalItems: data?.totalItems,
          },
        });
        const cleanedUsers = data?.rows ? data?.rows.map((user) => ({
          name: `${user.name ?? ''} ${user.firstLastname ?? ''} ${user.secondLastname ?? ''}`,
          phone: user.phone,
          email: user.email,
          state: user?.colony?.name || '',
          createdAt: formatDate(user.createdAt || ''),
          status: !user.deletedAt ? (
            <UIBadge text="Validado" variant="primary" />
          ) : (
            <UIBadge text="Inactivo" variant="danger" />
          ),
          id: user.id,
        })) : [];
        dispatch({ type: ActionTypes.SET_USERS, payload: [...cleanedUsers] });
        dispatch({ type: ActionTypes.SET_IS_LOADING, payload: false });
      });
  }, [filterValue, currentPage]);

  const handlePageChange = (event: any) => {
    dispatch({ type: ActionTypes.SET_CURRENT_PAGE, payload: event.selected + 1 });
  };

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const downloadFile = async () => {
    try {
      BurySpinner.show();
      const anchorTag: HTMLAnchorElement = document.createElement('a');
      const response = await userExportExcel();
      anchorTag.href = URL.createObjectURL(response);
      anchorTag.download = `Reporte de usuarios ${new Date().toISOString().split('T')[0]}`;
      anchorTag.click();
      anchorTag.remove();
      return response;
    } catch (error) {
      return error;
    } finally {
      BurySpinner.hide();
    }
  };

  return (
    <SideBar activeView={ROUTES.USERS} title="Lista de usuarios">
      <UsersViewStyles>
        {showTableConfig && (
        <TableConfigBar
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          setCloseStateF={setShowTableConfig}
        />
        )}
        <div className="actions">
          <UIButton
            onClick={downloadFile}
            variant={canShowElements ? 'primary' : 'disabled'}
            text="Descargar"
            width="125px"
            height="28px"
            borderRadius="4px"
          />
          <AiOutlineSetting onClick={() => setShowTableConfig(true)} className="cursor-pointer" size="24px" />
        </div>
        {canShowElements ? <UITable columns={columns} rows={users} tableHeight="60%" />
          : <TableSkeleton skeletonRowsNumber={7} />}
        <div className="table-footer-data">
          {canShowElements && (
          <p>
            {`Se muestran ${users.length} de ${totalItems} resultados`}
          </p>
          )}
          {users.length && (
          <ReactPaginate
            activeClassName="activePage"
            className="paginator"
            pageCount={totalPages}
            onPageChange={handlePageChange}
            nextLabel={<FiChevronRight className="total-center" size="18px" />}
            previousLabel={<FiChevronLeft className="total-center" size="18px" />}
          />
          )}
        </div>
      </UsersViewStyles>
    </SideBar>
  );
}
