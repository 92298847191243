import { useContext } from 'react';
import BannerCard from 'shared/components/BannerCard';
import styled from 'styled-components';
import Store from '../dashboardStore';

const Banners = () => {
  const { state } = useContext(Store);
  const {
    banner1, banner2, banner3, color, secondaryColor,
  } = state;
  const cardValues = [banner1, banner2, banner3];

  return (
    <Container>
      {cardValues?.map((banner, key) => (
        <BannerCard
          bannerSerial={key as any}
          key={(banner as any)?.name}
          description={(banner as any)?.description ?? 'NA'}
          title={(banner as any)?.name ?? 'NA'}
          link={(banner as any)?.url ?? 'NA'}
          backgroundColor={color ?? '#F2994A'}
          imgSrc={(banner as any)?.content || (banner as any)?.imageUrl}
          linkColor={secondaryColor ?? '#F2994A'}
        />
      ))}
    </Container>
  );
};

export default Banners;

const Container = styled.div`
  padding-top: 20px;
  width: 100%;
`;
