import cardSeedData from 'core/data/cardSeedData';

export interface MultimediaProps {
  name: string;
  link: string;
  title: string;
  imageUrl: any;
  description: string;
  image?: File | null;
}

interface VideoProps {
  videoUrl: string;
  videoFile?: File | null;
}

export interface StateModel {
  video: VideoProps;
  banner1: MultimediaProps;
  banner2: MultimediaProps;
  banner3: MultimediaProps;
  color: string;
  secondaryColor: string,
  loading: boolean;
  success: boolean;
}

export enum ActionTypes {
  UPDATE_DASHBOARD = 'UPDATE_DASHBOARD',
  UPDATE_IMAGE = 'UPDATE_IMAGE',
  UPDATE_VIDEO = 'UPDATE_VIDEO',
  UPDATE_COLOR = 'UPDATE_COLOR',
  UPDATE_SECONDARY_COLOR = 'UPDATE_SECONDARY_COLOR',
  LOAD_VIDEO_URL = 'LOAD_VIDEO_URL',
  LOAD_BANNERS = 'LOAD_BANNERS',
  SET_LOADING = 'SET_LOADING',
  SET_SUCCESS = 'SET_SUCCESS',
  NO_RESULTS = 'NO_RESULTS',
  LOAD_VIDEO_FILE = 'LOAD_VIDEO_FILE',
}

interface Action {
  type: string;
  payload?: any;
}
const defaultColor = '#002A77';
const secondaryDefaultColor = '#F2F2F2';

export const initialState: StateModel = {
  video: {
    videoUrl: '',
  },
  banner1: {
    name: 'banner1',
    link: cardSeedData[0].url,
    title: cardSeedData[0].cardTitle,
    description: cardSeedData[0].cardText,
    imageUrl: cardSeedData[0].imageUrl,
  },
  banner2: {
    name: 'banner2',
    link: cardSeedData[1].url,
    title: cardSeedData[1].cardTitle,
    description: cardSeedData[1].cardText,
    imageUrl: cardSeedData[1].imageUrl,
  },
  banner3: {
    name: 'banner3',
    link: cardSeedData[2].url,
    title: cardSeedData[2].cardTitle,
    description: cardSeedData[2].cardText,
    imageUrl: cardSeedData[2].imageUrl,
  },
  color: defaultColor,
  secondaryColor: secondaryDefaultColor,
  loading: false,
  success: false,
};

const dashboardReducer = (state: StateModel, action: Action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_DASHBOARD:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.UPDATE_COLOR:
      return {
        ...state,
        color: action.payload,
      };

    case ActionTypes.UPDATE_SECONDARY_COLOR:
      return {
        ...state,
        secondaryColor: action.payload,
        updatedAt: new Date(),
      };
    case ActionTypes.UPDATE_IMAGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case ActionTypes.UPDATE_VIDEO:
      return {
        ...state,
        video: action.payload,
      };
    case ActionTypes.LOAD_BANNERS:

      return {
        ...state,
        banner1: {
          ...state.banner1,
          title: action.payload.banner1.name,
          description: action.payload.banner1.description,
          imageUrl: action.payload.banner1.content,
          link: action.payload.banner1.url,
          updatedAt: action.payload.banner1.updatedAt,
        },
        banner2: {
          ...state.banner2,
          title: action.payload.banner2.name,
          description: action.payload.banner2.description,
          imageUrl: action.payload.banner2.content,
          link: action.payload.banner2.url,
          updatedAt: action.payload.banner2.updatedAt,
        },
        banner3: {
          ...state.banner3,
          title: action.payload.banner3.name,
          description: action.payload.banner3.description,
          imageUrl: action.payload.banner3.content,
          link: action.payload.banner3.url,
          updatedAt: action.payload.banner3.updatedAt,
        },
      };
    case ActionTypes.LOAD_VIDEO_URL:
      return {
        ...state,
        video: { ...state.video, videoUrl: action.payload },
      };
    case ActionTypes.LOAD_VIDEO_FILE:
      return {
        ...state,
        video: { ...state.video, videoFile: action.payload },
      };
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ActionTypes.SET_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    default:
      throw new Error();
  }
};

export default dashboardReducer;
