/**
 * Generated by orval v6.14.1 🍺
 * Do not edit manually.
 * SLP Smart Cities
 * Swagger smart cities api documentation.
 * OpenAPI spec version: 1.0.0
 */
import { axiosInstance } from '../core/api/http/axios-config';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;

export type StartUrl200 = {
  success?: boolean;
  message?: string;
  data?: Url;
};

export type GetSession200DataSession = {
  id?: number;
  auth?: number;
};

export type GetSession200Data = {
  session?: GetSession200DataSession;
};

export type GetSession200 = {
  success?: boolean;
  message?: string;
  data?: GetSession200Data;
};

export type CreateSession200 = {
  success?: boolean;
  message?: string;
  data?: Session;
};

export type ListUserPaginate200MetaDirection = typeof ListUserPaginate200MetaDirection[keyof typeof ListUserPaginate200MetaDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListUserPaginate200MetaDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

export type ListUserPaginate200Meta = {
  limit?: number;
  sortBy?: string;
  direction?: ListUserPaginate200MetaDirection;
};

export type ListUserPaginate200Data = {
  totalItems?: number;
  currentPage?: number;
  totalPages?: number;
  rows?: User[];
};

export type ListUserPaginate200 = {
  success?: boolean;
  message?: string;
  data?: ListUserPaginate200Data;
  meta?: ListUserPaginate200Meta;
};

export type ListUserPaginateParams = {
/**
 * page to query
 */
page?: PageParameter;
/**
 * number of items you want the response to return
 */
limit?: LimitParameter;
/**
 * field by which you want to sort
 */
sortBy?: SortByParameter;
/**
 * sort type, descending or ascending
 */
direction?: DirectionParameter;
name?: string;
email?: string;
phone?: string;
colonyId?: number;
};

export type UpdateBanner200 = {
  success?: boolean;
  message?: string;
  data?: PortalConfig;
};

export type SendCode200 = {
  message?: string;
  success?: boolean;
  data?: Codes;
};

export type ListSanLuisPotosiColonies200 = {
  success?: boolean;
  message?: string;
  data?: Colonies[];
};

export type UpdateVideo200 = {
  success?: boolean;
  message?: string;
  data?: PortalConfig;
};

export type ListMedia200Data = {[key: string]: PortalConfig};

export type ListMedia200 = {
  success?: boolean;
  message?: string;
  data?: ListMedia200Data;
};

export type DeleteMedia200 = {
  success?: boolean;
  message?: string;
  data?: PortalConfig;
};

export type GetMedia200 = {
  success?: boolean;
  message?: string;
  data?: PortalConfig;
};

export type ListColors200 = {
  success?: boolean;
  message?: string;
  data?: Colors;
};

export type ChangeColor200 = {
  success?: boolean;
  message?: string;
  data?: PortalConfig;
};

export type ChangeColorBody = {
  color: string;
};

export type VerifiedUser200 = {
  success?: boolean;
  message?: string;
  data?: User;
};

export type RegisterUser200 = {
  success?: boolean;
  message?: string;
  data?: User;
};

export type VerifyPhone409 = {
  message?: string;
  sucess?: boolean;
  errors?: string;
  data?: User;
};

export type VerifyPhone200 = {
  message?: string;
  success?: boolean;
  data?: User;
};

export type VerifyPhoneBody = {
  phone?: string;
};

/**
 * end date to filter
 */
export type ToDateParameter = string;

/**
 * start date to filter
 */
export type FromDateParameter = string;

export type StatusParameter = typeof StatusParameter[keyof typeof StatusParameter];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusParameter = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;

/**
 * text you want to search for in the entire table
 */
export type SearchParameter = string;

/**
 * number of items you want the response to return
 */
export type LimitParameter = number;

/**
 * field by which you want to sort
 */
export type SortByParameter = string;

export type DirectionParameter = typeof DirectionParameter[keyof typeof DirectionParameter];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DirectionParameter = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

/**
 * page to query
 */
export type PageParameter = number;

export type Error500Response = {
  errors?: string;
  success?: boolean;
};

export type Error422Response = {
  errors?: string[];
  success?: boolean;
};

export type Error409Response = {
  errors?: string[];
  success?: boolean;
};

export type Error404Response = {
  errors?: string[];
  success?: boolean;
};

export type Error403Response = {
  errors?: string;
  success?: boolean;
};

export type Error401Response = {
  errors?: string;
  success?: boolean;
};

export type Error400Response = {
  errors?: string;
  success?: boolean;
};

export interface Url {
  url?: string;
}

export type SessionSession = {
  readonly id?: number;
};

export interface Session {
  session?: SessionSession;
  token?: string;
}

export interface SessionCreate {
  phone?: string;
}

export interface Gateway {
  readonly id?: number;
  gwId?: string;
  gwSn?: string;
  gwAddress?: string;
  gwPort?: number;
}

export interface GatewayCreate {
  gw_id: string;
  gw_sn: string;
  gw_address: string;
  gw_port: number;
  mac?: string;
  ip?: string;
}

export type BannerBannerType = typeof BannerBannerType[keyof typeof BannerBannerType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BannerBannerType = {
  banner1: 'banner1',
  banner2: 'banner2',
  banner3: 'banner3',
} as const;

export interface Banner {
  image?: Blob;
  bannerType: BannerBannerType;
  description?: string;
  title?: string;
  link?: string;
}

export interface Colonies {
  readonly id?: number;
  name: string;
}

export interface Video {
  video: Blob;
}

export interface PortalConfig {
  keySlot?: string;
  content?: string;
  name?: string;
  description?: string;
  url?: string;
  updatedAt?: string;
}

export interface Colors {
  color_primary?: string;
  color_secondary?: string;
  color_accent?: string;
}

export interface VerifyCode {
  phone?: string;
  code: string;
}

export interface RestorePassword {
  email: string;
  code: string;
  password: string;
  confirmedPassword: string;
}

export interface UserVerifyEmail {
  success?: boolean;
  message?: string;
  data?: UserVerifyEmailData;
}

export interface Phone {
  phone: string;
}

export type EmailTypeCode = typeof EmailTypeCode[keyof typeof EmailTypeCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EmailTypeCode = {
  recovery: 'recovery',
  register: 'register',
} as const;

export interface Email {
  email: string;
  typeCode: EmailTypeCode;
}

export interface Codes {
  phone?: string;
}

export interface Role {
  id?: number;
  name?: string;
}

export interface User {
    secondLastname: string;
    firstLastname: string;
  readonly id?: number;
  name: string;
  email?: string;
  phone: string;
  colonyId?: number;
  colony: Colonies;
  readonly phoneVerifiedAt?: string | null;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string | null;
  roles?: Role[];
  verified?: boolean;
}

export type UserVerifyEmailData = {
  user?: User;
};

export type AuthDataViewPermissionsItem = {
  name?: string;
  isView?: string;
};

export type AuthData = {
  user?: User;
  token?: string;
  viewPermissions?: AuthDataViewPermissionsItem[];
};

export interface Auth {
  success?: boolean;
  message?: string;
  data?: AuthData;
}

export interface Login {
  email: string;
  password: string;
}





  /**
 * user login for access the panel admin
 * @summary Login of admin
 */
export const login = (
    login: Login,
 ) => {
      return axiosInstance<Auth>(
      {url: `/login`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: login
    },
      );
    }

/**
 * Verify the phone in the database and if it is verified with your code.
 * @summary Verify Phone
 */
export const verifyPhone = (
    verifyPhoneBody: VerifyPhoneBody,
 ) => {
      return axiosInstance<VerifyPhone200>(
      {url: `/verify-phone`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: verifyPhoneBody
    },
      );
    }

/**
 * endpoint to register user in app
 * @summary Register of user
 */
export const registerUser = (
    user: NonReadonly<User>,
 ) => {
      return axiosInstance<RegisterUser200>(
      {url: `/register`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: user
    },
      );
    }

/**
 * endpoint to verified phone user in backend
 * @summary Verified Register of user
 */
export const verifiedUser = (
    verifyCode: VerifyCode,
 ) => {
      return axiosInstance<VerifiedUser200>(
      {url: `/verified-register`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: verifyCode
    },
      );
    }

/**
 * update defined portal color
 * @summary Change Color
 */
export const changeColor = (
    colorType: 'primary' | 'secondary' | 'accent',
    changeColorBody: ChangeColorBody,
 ) => {
      return axiosInstance<ChangeColor200>(
      {url: `/colors/${colorType}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: changeColorBody
    },
      );
    }

/**
 * list of portal Colors
 * @summary List of Colors
 */
export const listColors = (

 ) => {
      return axiosInstance<ListColors200>(
      {url: `/colors`, method: 'get'
    },
      );
    }

/**
 * get media url from server
 * @summary get multimedia
 */
export const getMedia = (
    mediaType: 'video' | 'banner1' | 'banner2' | 'banner3',
 ) => {
      return axiosInstance<GetMedia200>(
      {url: `/media/${mediaType}`, method: 'get'
    },
      );
    }

/**
 * delete mediaType banners of video
 * @summary delete mediaType
 */
export const deleteMedia = (
    mediaType: 'video' | 'banner1' | 'banner2' | 'banner3',
 ) => {
      return axiosInstance<DeleteMedia200>(
      {url: `/media/${mediaType}`, method: 'delete'
    },
      );
    }

/**
 * list media url from server
 * @summary list multimedia
 */
export const listMedia = (

 ) => {
      return axiosInstance<ListMedia200>(
      {url: `/media`, method: 'get'
    },
      );
    }

/**
 * update media url from server
 * @summary update multimedia video
 */
export const updateVideo = (
    video: Video,
 ) => {const formData = new FormData();
formData.append('video', video.video)

      return axiosInstance<UpdateVideo200>(
      {url: `/video`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }

/**
 * list of all San Luis Potosi Colonies
 * @summary List of San Luis Potosi Colonies
 */
export const listSanLuisPotosiColonies = (

 ) => {
      return axiosInstance<ListSanLuisPotosiColonies200>(
      {url: `/colonies`, method: 'get'
    },
      );
    }

/**
 * generate the code and send it as SMS
 * @summary Generate Code for verify phone
 */
export const sendCode = (
    phone: Phone,
 ) => {
      return axiosInstance<SendCode200>(
      {url: `/codes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: phone
    },
      );
    }

/**
 * update banner
 * @summary update banner
 */
export const updateBanner = (
    banner: Banner,
 ) => {const formData = new FormData();
if(banner.image !== undefined) {
 formData.append('image', banner.image)
 }
formData.append('bannerType', banner.bannerType)
if(banner.description !== undefined) {
 formData.append('description', banner.description)
 }
if(banner.title !== undefined) {
 formData.append('title', banner.title)
 }
if(banner.link !== undefined) {
 formData.append('link', banner.link)
 }

      return axiosInstance<UpdateBanner200>(
      {url: `/banners`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }

/**
 * list of all users paginate
 * @summary List of users
 */
export const listUserPaginate = (
    params?: ListUserPaginateParams,
 ) => {
      return axiosInstance<ListUserPaginate200>(
      {url: `/users-paginate`, method: 'get',
        params
    },
      );
    }

/**
 * export of users in the system
 * @summary Users Export Excel
 */
export const userExportExcel = (

 ) => {
      return axiosInstance<Blob>(
      {url: `/users/export-excel`, method: 'get',
        responseType: 'blob'
    },
      );
    }

/**
 * create a session by phone
 * @summary Create a Session
 */
export const createSession = (
    sessionCreate: SessionCreate,
 ) => {
      return axiosInstance<CreateSession200>(
      {url: `/sessions`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sessionCreate
    },
      );
    }

/**
 * get a Session by id
 * @summary get a Session
 */
export const getSession = (
    sessionId: number,
 ) => {
      return axiosInstance<GetSession200>(
      {url: `/sessions/${sessionId}/`, method: 'get'
    },
      );
    }

/**
 * Generation of URL and redirection
 * @summary url generation and redirection
 */
export const startUrl = (
    sessionId: number,
 ) => {
      return axiosInstance<StartUrl200>(
      {url: `/sessions/${sessionId}/url`, method: 'get'
    },
      );
    }

export type LoginResult = NonNullable<Awaited<ReturnType<typeof login>>>
export type VerifyPhoneResult = NonNullable<Awaited<ReturnType<typeof verifyPhone>>>
export type RegisterUserResult = NonNullable<Awaited<ReturnType<typeof registerUser>>>
export type VerifiedUserResult = NonNullable<Awaited<ReturnType<typeof verifiedUser>>>
export type ChangeColorResult = NonNullable<Awaited<ReturnType<typeof changeColor>>>
export type ListColorsResult = NonNullable<Awaited<ReturnType<typeof listColors>>>
export type GetMediaResult = NonNullable<Awaited<ReturnType<typeof getMedia>>>
export type DeleteMediaResult = NonNullable<Awaited<ReturnType<typeof deleteMedia>>>
export type ListMediaResult = NonNullable<Awaited<ReturnType<typeof listMedia>>>
export type UpdateVideoResult = NonNullable<Awaited<ReturnType<typeof updateVideo>>>
export type ListSanLuisPotosiColoniesResult = NonNullable<Awaited<ReturnType<typeof listSanLuisPotosiColonies>>>
export type SendCodeResult = NonNullable<Awaited<ReturnType<typeof sendCode>>>
export type UpdateBannerResult = NonNullable<Awaited<ReturnType<typeof updateBanner>>>
export type ListUserPaginateResult = NonNullable<Awaited<ReturnType<typeof listUserPaginate>>>
export type UserExportExcelResult = NonNullable<Awaited<ReturnType<typeof userExportExcel>>>
export type CreateSessionResult = NonNullable<Awaited<ReturnType<typeof createSession>>>
export type GetSessionResult = NonNullable<Awaited<ReturnType<typeof getSession>>>
export type StartUrlResult = NonNullable<Awaited<ReturnType<typeof startUrl>>>
