import tokens from 'generated/style-tokens/tokens.json';
import {
  TypographyTokensType,
  ColorsTokensType,
} from 'generated/style-tokens/tokens-types';

const getTokenValues = (token: TypographyTokensType | ColorsTokensType | any) => {
  const tokenValue = tokens[token];
  return tokenValue;
};

export default getTokenValues;
