import styled from 'styled-components';

interface SpacerProps {
  margin: 1 | 2 | 3 | 4 | 5 | 6;
}

const Spacer = styled.div<SpacerProps>`
margin-top: ${({ margin }) => margin}rem`;

export default Spacer;
