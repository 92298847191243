import {
  AliasColorsTokensTypes,
  AliasColorsTokensKeys,
} from 'generated/style-tokens/tokens-types';

const getColorFamilies = (token: AliasColorsTokensTypes) => {
  const getToken = AliasColorsTokensKeys[token];
  return getToken;
};

export default getColorFamilies;
