import styled from 'styled-components';
import getTokenValues from 'core/utils/getTokensValues';
import Typography from 'shared/components/Typography';
import getColorFamilies from 'core/utils/getColorTokens';

const SetMediaInputStyles = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 310px;
  & input{
    width: 100%;
    height: 30px;
    outline: none;
    background-color: white;
    border: 1px solid ${getTokenValues('300')};
    border-radius: 4px;
    color: ${getColorFamilies('alias-text-primary')};
    padding-left: 18px;
    margin-top: 8px;
    font-family: 'Noto Sans'
  }
`;
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string,
}
export default function SetMediaInput({ label, ...rest }: Props) {
  return (
    <SetMediaInputStyles className="mt-1">
      <Typography variant="text-04-medium" color="0">
        {label}
      </Typography>
      <input {...rest} />
    </SetMediaInputStyles>
  );
}
