import 'core/styles/DashboardLayoutStyles.css';
import {
  useReducer, useMemo, useEffect,
} from 'react';
import { listMedia, listColors } from 'services';
import SideBar from 'shared/template/SideBar';
import ROUTES from 'routes/routes';
import dashboardReducer, {
  initialState,
  ActionTypes,
} from './dashboardReducer';
import Store from './dashboardStore';
import ContentView from './SetContentView';

export default function Dashboard() {
  const [state, dispatch] = useReducer(dashboardReducer, initialState);
  const storeValue = useMemo(() => ({ state, dispatch }), [state]);

  useEffect(() => {
    const getMedia = () => {
      const media = listMedia();
      const colors = listColors();

      Promise.all([media, colors]).then((response) => {
        if (response[0]?.data && Object.keys(response[0]?.data).length > 0) {
          dispatch({
            type: ActionTypes.LOAD_BANNERS,
            payload: { ...response[0].data },
          });
          dispatch({
            type: ActionTypes.LOAD_VIDEO_URL,
            payload: response[0].data?.video?.content,
          });
        }
        if (response[1]?.data && Object.keys(response[1]?.data).length > 0) {
          dispatch({
            type: ActionTypes.UPDATE_COLOR,
            payload: response[1].data?.color_primary,
          });
          dispatch({
            type: ActionTypes.UPDATE_SECONDARY_COLOR,
            payload: response[1].data?.color_secondary,
          });
        }
      });
    };

    getMedia();
  }, [dispatch]);

  return (
    <Store.Provider value={storeValue}>
      <SideBar activeView={ROUTES.CONTENT_DESIGN} title="Diseño de portal cautivo">
        <ContentView />
      </SideBar>
    </Store.Provider>
  );
}
