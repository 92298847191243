import styled from 'styled-components';

export type TableColumn = {
  label: string,
  align: string,
};

interface Props {
  columns?: TableColumn[],
  rows?: Record<string | number | any, any>[],
  rowTextAlign?: 'left' | 'center' | 'right',
  tableHeight?: string,
}

const UsersTableStyles = styled.div<{ height: string }>`
  box-sizing: border-box;
  width: 100%;
  height: ${(props) => props.height};
  border: 1px solid #E4E4EF;
  border-radius: 8px;
  overflow-y: auto !important;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 4px;
  }
  & table {
    border-collapse: collapse;
    width: 100%;
    /* height: 100%; */
  }
  & .tbody{
    overflow-y: auto;
    height: 50px;
  }
  & thead tr th{
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.03em;
    color: #101828;
    padding: 12px;
    max-width: 150px;
    border-bottom: 1px solid #E4E4EF;
  }
  & tbody tr td{
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: #667085;
    padding: 12px;
    max-width: 90px;
    border-bottom: 1px solid #E4E4EF;
  }
  & tr:hover{
    background-color: #E4E7EC;
  }
`;
export default function UITable({
  columns = [], rows = [], rowTextAlign = 'left', tableHeight = '85%',
}: Props) {
  return (
    <UsersTableStyles height={tableHeight}>
      <table>
        <thead>
          <tr>
            {columns?.length && columns.map((column) => (
              <th
                title={column.label}
                className={`text-${column.align} truncate`}
                key={column.label}
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="tbody">
          {rows?.length ? rows.map((row) => (
            <tr key={row.id}>
              {Object.keys(row)
                .map((value) => value !== 'id' && (
                  <td
                    title={row[value]}
                    className={`truncate text-${rowTextAlign}`}
                    key={row[value]}
                  >
                    {row[value]}
                  </td>
                ))}
            </tr>
          )) : <tr />}
        </tbody>
      </table>
    </UsersTableStyles>
  );
}
