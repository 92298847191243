import styled from 'styled-components';
import getColorFamilies from 'core/utils/getColorTokens';
import Typography from 'shared/components/Typography';
import { TypographyTokensType } from 'generated/style-tokens/tokens-types';

const StyledErrorSpan = styled.div`
  color: ${getColorFamilies('alias-text-error')} !important;
  margin-top: 7px;
`;

interface MediaErrorSpanProps {
  error: string;
  variant?: TypographyTokensType
}

const MediaErrorSpan = ({ error, variant }: MediaErrorSpanProps) => (
  <Typography variant={variant ?? 'text-04-medium'}>
    <StyledErrorSpan>{error}</StyledErrorSpan>
  </Typography>
);

export default MediaErrorSpan;
