import axios, { AxiosInstance } from 'axios';

const token = JSON.parse(localStorage.getItem('token') as string);
const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SLP_API_URL,
});

api.defaults.headers.common.Authorization = `Baerer ${token}`;
api.interceptors.request.use((config) => config, (error) => Promise.reject(error));
api.interceptors.response.use((response) => response, (error) => Promise.reject(error));
// eslint-disable-next-line import/prefer-default-export
export { api };
