import Router from 'routes/Router';
import { AuthContextsProvider } from './core/AuthContext/AuthContext';

const App = () => (
  <AuthContextsProvider>
    <Router />
  </AuthContextsProvider>
);

export default App;
