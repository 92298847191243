import styled from 'styled-components';

interface Props {
  title: string;
  description: string;
  link: string;
  imgSrc?: string;
  backgroundColor?: string;
  linkColor: string;
  bannerSerial: string;
}

export default function BannerCard({
  title,
  description,
  link,
  imgSrc = '',
  backgroundColor = '#002A77',
  linkColor = '#FDE29B',
  bannerSerial,
}: Props) {
  return (
    <div>
      <BannerCardContainer
        style={{ display: 'inline-block' }}
        imgSrc={imgSrc}
        backgroundColor={backgroundColor}
        linkColor={linkColor}
      >
        <img id={bannerSerial} src={imgSrc} alt="banner" className="banner-img" />
        <div className="texts-container">
          <h1 className="card-title">
            {title ?? ''}
          </h1>
          <p className="truncate-multi-line banner-desc-text">
            {description ?? ''}
          </p>
          {link && (
          <button
            type="button"
            className="link-text cursor-pointer"
            onClick={() => {}}
          >
            Ir a la página
          </button>
          )}
        </div>
      </BannerCardContainer>

    </div>
  );
}

const BannerCardContainer = styled.div<{
  imgSrc: string;
  backgroundColor: string;
  linkColor: string;
}>`
  width: 100%;
  min-height:240px;
  background-image: url('${(props) => props.imgSrc}');
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  position: relative;
  text-align: left;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  background-color: rgba(0,0,0,0.2);

  & .banner-img{
    height: 130px;
    object-fit: cover;
    width: 100%;
    border-radius: 20px 20px 0 0;
  }
  & .texts-container {
    box-sizing: border-box;
    background: ${(props) => props.backgroundColor};
    position: absolute;
    width: 100%;
    bottom: 0;
    border-radius: 0 0 20px 20px;
    padding: 14px 24px;
  }
  & .card-title{
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    margin: 0;
    padding: 0;
    color: ${(props) => props.linkColor};
  }
  & .banner-desc-text {
    -webkit-line-clamp: 3;
    color: ${(props) => props.linkColor};
    opacity: 0.6;
    font-size: 12px;
    font-weight: 500;
    padding: 0;
    margin: 0;
  }
  & .link-text {
    outline: none;
    background-color: transparent;
    border: none;
    ${({ linkColor }) => `
      border-bottom: 1px solid ${linkColor};
      color: ${linkColor};
    `};
    font-weight: 500;
    font-size: 12px;
  }
`;
