import { useState } from 'react';
import LogoAlt from 'shared/components/LogoAlt';
import Typography from 'shared/components/Typography';
import Input from 'shared/components/Input';
import HidePassIcon from 'shared/components/HidePassIcon';
import EmailIcon from 'shared/components/EmailIcon';
import Button from 'shared/components/Button';
import Spacer from 'shared/components/Spacer';
import ShowPassIcon from 'shared/components/ShowPassIcon';
import ErrorAlert from 'shared/components/ErrorAlert';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'routes/routes';
import useAuth from 'hooks/useAuth';
import { BurySpinner } from '../../shared/BurySpinner';

interface StateModel {
  showPassword : boolean
  email: string;
  password: string
  error: boolean
}

const initialState : StateModel = {
  showPassword: false,
  email: '',
  password: '',
  error: false,
};

const Login = () => {
  const user = useAuth();
  const { loginService } = user;
  const [state, setState] = useState<StateModel>(initialState);
  const {
    showPassword,
    email,
    password,
    error,
  } = state;
  const navigate = useNavigate();
  const hidePassword = () => setState({ ...state, showPassword: !showPassword });
  const handleChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    const { target: { name, value } } = event;
    setState({ ...state, [name]: value });
  };

  if (user.getIsLoggedIn()) navigate(ROUTES.CONTENT_DESIGN);

  const onSubmit = async () => {
    if (email && password && loginService) {
      try {
        BurySpinner.show();
        await loginService(email, password);
        BurySpinner.hide();
        navigate(ROUTES.CONTENT_DESIGN, { replace: true });
      } catch (e) {
        BurySpinner.hide();
        setState({ ...state, error: true });
      }
    }
    setState({ ...state, error: true });
  };

  return (
    <div className="admin-container">
      {' '}
      <div className="admin-child">
        <LogoAlt />
      </div>
      <div className="admin-child">
        <Typography variant="title-03-bold" color="100">
          ¡Bienvenido!
        </Typography>
        <Typography variant="text-03-medium" color="100">
          Al administrador del portal cautivo
        </Typography>
        <Spacer margin={3} />
        <div className="admin-item">
          <Input
            icon={<EmailIcon />}
            placeholder="Email"
            type="email"
            value={email}
            name="email"
            onChange={(event) => handleChange(event)}
          />
        </div>
        <div className="mt-1" />
        <div className="admin-item">
          <Input
            icon={showPassword ? <ShowPassIcon /> : <HidePassIcon />}
            placeholder="Contraseña"
            onIconClick={hidePassword}
            type={showPassword ? 'text' : 'password'}
            value={password}
            name="password"
            onChange={(event) => handleChange(event)}
          />
        </div>
        <Spacer margin={1} />
        {error && (
          <div className="admin-item error-msg">
            <ErrorAlert>
              <Typography variant="subtitle-05-semibold" color="1">
                Las credenciales son inválidas
              </Typography>
            </ErrorAlert>
          </div>
        )}
        <Spacer margin={2} />
        <div className="admin-item">
          <Button className="login-btn" onClick={onSubmit}>Entrar</Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
