import { useRef, useState, useEffect } from 'react';
import ReloadIcon from './svgIcons/ReloadIcon';

interface VideoPlayerProps {
  videoUrl?: string;
}

const VideoPlayer = ({ videoUrl }: VideoPlayerProps) => {
  const [showReplay, setShowReplay] = useState(false);
  const videoRef = useRef<any>(null);
  const replayVideo = () => {
    videoRef.current.play();
    setShowReplay(false);
  };

  const onEndedVideo = () => {
    setShowReplay(true);
  };

  useEffect(() => {
    videoRef.current?.load();
  }, [videoUrl]);

  return (
    <div>
      <div className="video-container" style={{ display: 'inline-block' }}>
        <video
          className="video-frame"
          autoPlay
          controls
          muted
          playsInline
          onEnded={onEndedVideo}
          ref={videoRef}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
        {showReplay && (
          <div className="video-overlay">
            <button
              className="button-unstyled"
              type="button"
              onClick={replayVideo}
            >
              <ReloadIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
