import styled from 'styled-components';
import PhoneDevice from '../../../shared/components/PhoneDevice';
import DashboardSetContentSection from './DashboardSetContentSection';

const SetContentView = () => (
  <SetContentViewStyles>
    <div className="view-left-content total-center">
      <PhoneDevice />
    </div>
    <div className="view-right-content">
      <DashboardSetContentSection />
    </div>
  </SetContentViewStyles>
);

export default SetContentView;

const SetContentViewStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr 380px;
  grid-template-rows: 1fr;
  box-sizing: border-box;
  & .view-left-content {
    box-sizing: border-box;
  }
  & .view-right-content {
    box-sizing: border-box;
  }
`;
