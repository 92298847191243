import styled from 'styled-components';

const TableSkeletonStyles = styled.div`
  width: 100%;
  .row {
    display: grid;
    grid-template-columns: 1fr 2fr 3fr 1fr 1fr;
    grid-template-rows: 100px;
    gap: 10px;
  }
  .skeleton {
    background: #e1e1e1;
    border-radius: 4px;
    height: 50px;
    position: relative;
    overflow: hidden;
  }
  .skeleton::before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
  @keyframes load {
    from { left: -150px; }
    to { left: 100%; }
  }
`;

interface Props {
  skeletonRowsNumber: number
}

export default function TableSkeleton({ skeletonRowsNumber = 4 }: Props) {
  const rows = Array.from({ length: skeletonRowsNumber }, (_, index) => index);

  return (
    <TableSkeletonStyles>
      {rows.map((n) => (
        <div key={n} className="row">
          <div className="skeleton" />
          <div className="skeleton" />
          <div className="skeleton" />
          <div className="skeleton" />
          <div className="skeleton" />
        </div>
      ))}
    </TableSkeletonStyles>
  );
}
