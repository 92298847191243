import {
  createContext, useMemo, useState,
} from 'react';
import { login } from 'services';
import type {
  AuthData, AuthDataViewPermissionsItem, User,
} from 'services';
import { api } from 'core/api/api';

type AuthMethods = {
  loginService?: { (email: string, password: string):Promise<unknown> },
  userHasPermission?: { (name: string): boolean },
  removeUserData?: { () : void },
  getIsLoggedIn: { ():boolean }
};

const AuthContext = createContext<AuthData & AuthMethods>({
  token: JSON.parse(localStorage.getItem('token') as string) || '',
  user: JSON.parse(localStorage.getItem('user') as string) as User || {} as User,
  viewPermissions: JSON.parse(localStorage.getItem('view-permissions') as string) as AuthDataViewPermissionsItem[] || [],
  getIsLoggedIn: () => false,
});

export function AuthContextsProvider({ children }: { children: JSX.Element }) {
  const [userAuth, setUserAuth] = useState<AuthData>({
    token: JSON.parse(localStorage.getItem('token') as string) || '',
    user: JSON.parse(localStorage.getItem('user') as string) as User || {} as User,
    viewPermissions: JSON.parse(localStorage.getItem('view-permissions') as string) as AuthDataViewPermissionsItem[] || [],
  });
  const authContextValue = useMemo(() => ({
    loginService(email: string, password: string) {
      return new Promise((resolve, reject) => {
        login({ email, password })
          .then((resp) => {
            api.defaults.headers.common.Authorization = `Baerer ${resp.data?.token}`;
            localStorage.setItem('token', JSON.stringify(resp.data?.token));
            localStorage.setItem('user', JSON.stringify(resp.data?.user));
            localStorage.setItem('view-permissions', JSON.stringify(resp.data?.viewPermissions));
            setUserAuth(() => ({
              token: resp.data?.token,
              user: resp.data?.user,
              viewPermissions: resp.data?.viewPermissions,
            }));
            resolve(resp);
          })
          .catch((error) => reject(error));
      });
    },
    userHasPermission(viewName: string): boolean {
      if (userAuth.viewPermissions?.length) {
        const userViewsNames = userAuth.viewPermissions.map((value) => value.isView);
        return userViewsNames.includes(viewName);
      }
      return false;
    },
    getIsLoggedIn(): boolean {
      return !!(userAuth.user && userAuth.token);
    },
    removeUserData() {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('view-permissions');
      setUserAuth(() => ({
        token: undefined,
        user: undefined,
        viewPermissions: [],
      }));
    },
    ...userAuth,
  }), [userAuth]);

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
}
export default AuthContext;
