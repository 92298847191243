import { TableColumn } from 'core/ui/UITable';

const columns: TableColumn[] = [
  { label: 'Nombre completo', align: 'left' },
  { label: 'Teléfono', align: 'left' },
  { label: 'Email', align: 'left' },
  { label: 'Colonia', align: 'left' },
  { label: 'Registro', align: 'left' },
  { label: 'Estatus', align: 'left' },
];

export default columns;
