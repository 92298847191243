import type React from 'react';
import styled from 'styled-components';
import Typography from './Typography';
import getColorFamilies from '../../core/utils/getColorTokens';

const SideBarItemContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  padding-left: 24px;
  gap: 13px;
  &:hover{
    background-color: ${getColorFamilies('alias-background-secundary')};
  }
  & .truncate{
    width: 70%;
  }
  &.active{
    background-color: ${getColorFamilies('alias-background-secundary')};
  }
`;

interface Props {
  icon: React.ReactNode,
  text: string,
  onClickAction: { () : unknown }
  extraClass?: string
}
function SidebarItem({
  text, icon, onClickAction, extraClass = '',
}: Props): JSX.Element {
  return (
    <SideBarItemContainer onClick={onClickAction} className={`flex row-direction align-items-center cursor-pointer ${extraClass}`}>
      {icon}
      <Typography variant="text-02-regular" color="1" className="truncate">
        {text}
      </Typography>
    </SideBarItemContainer>
  );
}
export default SidebarItem;

SidebarItem.defaultProps = {
  extraClass: '',
};
