import styled from 'styled-components';
import getColorFamilies from 'core/utils/getColorTokens';

type Variants = 'primary' | 'danger';

interface Props {
  text: string;
  variant: Variants;
}

const StyledBadge = styled.div`
  display: flex;
  padding: 5px 0 5px 0;
  border-radius: 10px;
  justify-content: center;
  &.primary{
    color: ${getColorFamilies('alias-text-primary')};
    background-color: #B9E6FE;
  }
  &.danger{
    color: ${getColorFamilies('alias-text-primarytwo')};
    background-color: #FEE4E2;
  }
`;

const UIBadge = ({ text, variant }: Props) => (
  <StyledBadge className={variant}>
    {text}
  </StyledBadge>
);

export default UIBadge;
