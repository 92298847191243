import styled from 'styled-components';
import getColorFamilies from 'core/utils/getColorTokens';

const ErrorAlert = styled.div`
display: flex;
background-color: ${getColorFamilies('alias-button-error')};
padding: 15px;
justify-content: center;
border-radius: 8px;
`;

export default ErrorAlert;
