import tokens from 'generated/style-tokens/tokens.json';

export type TypographyTokensType =
  | 'heading-01-bold'
  | 'heading-02-bold'
  | 'title-01-bold'
  | 'title-02-bold'
  | 'title-03-bold'
  | 'title-04-bold'
  | 'subtitle-01-semibold'
  | 'subtitle-02-semibold'
  | 'subtitle-03-semibold'
  | 'subtitle-04-semibold'
  | 'subtitle-05-semibold'
  | 'subtitle-06-semibold'
  | 'text-01-regular'
  | 'text-02-regular'
  | 'text-03-regular'
  | 'text-04-regular'
  | 'text-05-regular'
  | 'text-01-medium'
  | 'text-02-medium'
  | 'text-03-medium'
  | 'text-04-medium'
  | 'text-05-medium'
  | 'text-button';

export const TypographyTokensKeys = [
  'heading-01-bold',
  'heading-02-bold',
  'title-01-bold',
  'title-02-bold',
  'title-03-bold',
  'title-04-bold',
  'subtitle-01-semibold',
  'subtitle-02-semibold',
  'subtitle-03-semibold',
  'subtitle-04-semibold',
  'subtitle-05-semibold',
  'subtitle-06-semibold',
  'text-01-regular',
  'text-02-regular',
  'text-03-regular',
  'text-04-regular',
  'text-05-regular',
  'text-01-medium',
  'text-02-medium',
  'text-03-medium',
  'text-04-medium',
  'text-05-medium',
  'text-button',
];

export type ColorsTokensType =
  | '0'
  | '1'
  | '25'
  | '50'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900';

export const ColorsTokensKeys = [
  '0',
  '1',
  '25',
  '50',
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
];

export type FontWeightType =
  | 'Display Medium'
  | 'Regular'
  | 'Display Semibold'
  | 'Bold';

export type FontFamiliesType = 'Noto Sans';

export const FontFamilies = {
  NOTO_SANS: 'Noto Sans',
};

export type AliasColorsTokensTypes =
  | 'alias-background-primary'
  | 'alias-background-secundary'
  | 'alias-text-primary'
  | 'alias-text-primarytwo'
  | 'alias-text-secondary'
  | 'alias-text-tertiary'
  | 'alias-text-quaternary'
  | 'alias-text-quaternarytwo'
  | 'alias-text-error'
  | 'alias-input-primary'
  | 'alias-input-secondary'
  | 'alias-input-tertiary'
  | 'alias-button-primary'
  | 'alias-button-secondary'
  | 'alias-button-tertiary'
  | 'alias-button-error';

const {
  alias: {
    background,
    text,
    input,
    button,
  },
} = tokens;
export const AliasColorsTokensKeys = {
  'alias-background-primary': background.primary,
  'alias-background-secundary': background.secundary,
  'alias-text-primary': text.primary,
  'alias-text-primarytwo': text.primarytwo,
  'alias-text-secondary': text.secondary,
  'alias-text-tertiary': text.tertiary,
  'alias-text-quaternary': text.quaternary,
  'alias-text-quaternarytwo': text.quaternarytwo,
  'alias-text-error': text.error,
  'alias-input-primary': input.primary,
  'alias-input-secondary': input.secundary,
  'alias-input-tertiary': input.tertiary,
  'alias-button-primary': button.primary,
  'alias-button-secondary': button.secondary,
  'alias-button-tertiary': button.tertiary,
  'alias-button-error': button.error,
};
