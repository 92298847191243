const ShowPassIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33497 14.0148C0.888345 13.2369 0.888342 12.2808 1.33497 11.5029C3.68496 7.40976 7.44378 4.75879 11.6798 4.75879C15.9158 4.75879 19.6746 7.40972 22.0246 11.5028C22.4712 12.2807 22.4712 13.2369 22.0246 14.0148C19.6746 18.1079 15.9158 20.7588 11.6798 20.7588C7.44377 20.7588 3.68497 18.1079 1.33497 14.0148Z"
      stroke="#667085"
      strokeWidth="2"
    />
    <circle cx="11.6797" cy="12.7588" r="3" stroke="#667085" strokeWidth="2" />
  </svg>
);

export default ShowPassIcon;
