const HidePassIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.67969 3.77734L21.6797 19.7773"
      stroke="#667085"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0546 5.56781C14.2347 5.0457 14.8039 4.76841 15.326 4.94846C18.4478 6.02506 21.0835 8.43023 22.8918 11.5799C23.3668 12.4072 23.4799 13.3789 23.2316 14.2749C23.084 14.8071 22.5329 15.1189 22.0007 14.9714C21.4685 14.8238 21.1567 14.2728 21.3042 13.7405C21.4117 13.3531 21.3626 12.9332 21.1573 12.5757C19.5394 9.75772 17.2514 7.72803 14.674 6.83919C14.1519 6.65913 13.8746 6.08992 14.0546 5.56781ZM8.7503 5.71859C8.95814 6.23027 8.71183 6.81356 8.20014 7.02141C5.82167 7.98752 3.71737 9.93673 2.2022 12.5758C1.9326 13.0454 1.9326 13.6224 2.2022 14.092C4.42466 17.9629 7.89339 20.3339 11.6798 20.3339C14.367 20.3339 16.8801 19.1463 18.9146 17.0764C19.3017 16.6826 19.9349 16.6771 20.3288 17.0642C20.7226 17.4514 20.7281 18.0845 20.341 18.4784C17.9956 20.8646 14.9897 22.3339 11.6798 22.3339C6.99415 22.3339 2.94527 19.403 0.467746 15.0878C-0.155911 14.0015 -0.155916 12.6662 0.467735 11.58C2.16221 8.62863 4.58162 6.33253 7.44748 5.16844C7.95917 4.96059 8.54246 5.20691 8.7503 5.71859Z"
      fill="#667085"
    />
    <path
      d="M14.5892 12.6027C14.748 13.2345 14.6968 13.9009 14.4434 14.5011C14.1899 15.1012 13.748 15.6026 13.1844 15.9293C12.6208 16.2561 11.9662 16.3905 11.3194 16.3123C10.6726 16.234 10.0689 15.9474 9.59949 15.4956C9.13007 15.0439 8.82045 14.4517 8.71743 13.8084C8.61441 13.1651 8.7236 12.5058 9.02848 11.93C9.33337 11.3543 9.81738 10.8934 10.4074 10.6172C10.9973 10.3409 11.6612 10.2641 12.2987 10.3985"
      stroke="#667085"
      strokeWidth="2"
    />
  </svg>
);

export default HidePassIcon;
