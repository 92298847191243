import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './locales/en/enTranslations.json';
import esTranslations from './locales/es/esTranslations.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'es',
  resources: {
    en: {
      translations: enTranslations,
    },
    es: {
      translations: esTranslations,
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

i18n.languages = ['en', 'es'];

export default i18n;
