import Modal from './Modal';
import ThumbsUp from './ThumbsUp';

interface SuccessModalProps {
  caption: string;
  onClose: Function;
}

const SuccessModal = ({ caption, onClose }: SuccessModalProps) => (
  <Modal closeIconAction={onClose}>
    <div
      aria-hidden="true"
      onClick={(event) => event.stopPropagation()}
      className="flex justify-content-center full-height align-items-center column-direction"
    >
      <div>
        <ThumbsUp />
      </div>
      {caption}
    </div>
  </Modal>
);

export default SuccessModal;
