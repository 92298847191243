import styled from 'styled-components';

import Typography from 'shared/components/Typography';
import ColorSelector from 'core/ui/ColorSelector';

const ContentColorSelectorStyles = styled.div`

  & .mt-16 {
    margin-top: 17px;
  }
`;

interface Props {
  setMain: Function,
  setSecondary?: Function,
  secondaryColor?: string,
  mainColor?: string,
}

export default function ContentColorSelector({
  setMain,
  setSecondary,
  secondaryColor,
  mainColor,
}: Props) {
  function setMainColorHandler(color: string): void {
    setMain(color);
  }

  function setSecondaryColorHandler(color: string): void {
    setSecondary?.(color);
  }

  return (
    <ContentColorSelectorStyles>
      <Typography variant="subtitle-04-semibold">Colores</Typography>
      <Typography variant="text-04-medium" color="500">
        Escoja una de nuestras combinaciones de colores primarios y secundarios
        para el tema del portal cautivo
      </Typography>
      <Typography variant="text-04-medium" color="500" className="mt-16">
        Primarios
      </Typography>
      <ColorSelector selectedColor={mainColor} onChange={setMainColorHandler} />
      <Typography variant="text-04-medium" color="500" className="mt-16">
        Secundarios
      </Typography>
      <ColorSelector selectedColor={secondaryColor} onChange={setSecondaryColorHandler} />

    </ContentColorSelectorStyles>
  );
}
