import styled from 'styled-components';
import {
  TypographyTokensType,
  ColorsTokensType,
  FontWeightType,
  FontFamilies,
} from 'generated/style-tokens/tokens-types';
import getTokenValues from 'core/utils/getTokensValues';

export type TypographyVariant = TypographyTokensType;
type HtmlAs =
  | 'span'
  | 'label'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'div'
  | 'p'
  | 'b';

const getVariant = (variant : string) => {
  const defaultVariant : TypographyTokensType = 'text-01-regular';
  const FONT_WEIGHT_PARSER = {
    'Display Medium': '400',
    Regular: '500',
    'Display Semibold': '600',
    Bold: '700',
  };

  const typographyToken = getTokenValues(variant as TypographyTokensType);

  const propertiesToReturn = (properties : any) => (
    `
      font-size: ${properties.fontSize}px;
      font-weight: ${FONT_WEIGHT_PARSER[properties.fontWeight as FontWeightType]};
      font-family: ${FontFamilies.NOTO_SANS};
      font-size: ${properties.fontSize}px;
      text-decoration: ${properties.textDecoration};
      letter-spacing: ${
    properties.letterSpacing === '0%'
      ? 'normal'
      : properties.letterSpacing
    };
      paragraph-spacing: ${properties.paragraphSpacing};
      line-height: ${properties.lineHeight.toLowerCase()};
      `
  );

  if (typographyToken) {
    return propertiesToReturn(typographyToken);
  } return propertiesToReturn(defaultVariant);
};

interface StyledTypographyProps {
  variant: string;
  as?: HtmlAs;
  color?: ColorsTokensType | '0';
  textAlign?: 'left' | 'center' | 'right'
}

const StyledTypography = styled.div<StyledTypographyProps>` 
    ${({ variant }) => getVariant(variant)}
    text-align: ${({ textAlign }) => textAlign || 'left'};
    color: ${({ color }) => color && getTokenValues(color)}
`;

type Props = {
  className?: string;
  children: React.ReactNode;
  textAlign?: 'left' | 'center' | 'right';
  htmlAs?: HtmlAs | 'div';
  variant: TypographyVariant;
  color?: ColorsTokensType;
};

const Typography: React.FC<Props> = ({ variant, color, ...rest }: Props) => (
  <StyledTypography variant={variant} color={color} {...rest} />
);

export default Typography;

Typography.defaultProps = {
  className: '',
  textAlign: 'left',
  htmlAs: 'div',
  color: '900',
};
