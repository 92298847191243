const ReloadIcon = () => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.6" cx="27" cy="27" r="27" fill="#FEF3E2" />
    <path
      d="M37.0299 12.822C33.7695 11.1227 30.0117 10.4685 26.3227 10.9578C22.6451 11.4456 19.2344 13.0437 16.6012 15.512L11.5362 19.92V14.0247C11.5362 13.13 10.7669 12.4047 9.8179 12.4047C8.86891 12.4047 8.09961 13.13 8.09961 14.0247V23.7447C8.09961 24.6394 8.86891 25.3647 9.8179 25.3647H20.1277C21.0766 25.3647 21.8459 24.6394 21.8459 23.7447C21.8459 22.85 21.0766 22.1247 20.1277 22.1247H14.0695L18.9563 17.8719C18.9727 17.8575 18.9889 17.8429 19.0048 17.828C21.1139 15.8428 23.8503 14.5577 26.8015 14.1662C29.7527 13.7748 32.759 14.2981 35.3672 15.6576C37.9755 17.017 40.0445 19.1387 41.2626 21.7031C42.4806 24.2676 42.7816 27.1358 42.1203 29.8755C41.459 32.6153 39.8712 35.0782 37.5961 36.8932C35.321 38.7082 32.4819 39.7769 29.5065 39.9384C26.5312 40.0998 23.5807 39.3453 21.0998 37.7883C18.6189 36.2314 16.7418 33.9565 15.7515 31.3064C15.4361 30.4625 14.4549 30.0194 13.5598 30.3167C12.6648 30.614 12.1948 31.5391 12.5102 32.383C13.7481 35.6957 16.0944 38.5393 19.1956 40.4855C22.2968 42.4316 25.9848 43.3748 29.704 43.173C33.4232 42.9712 36.9721 41.6353 39.816 39.3666C42.6599 37.0978 44.6446 34.0191 45.4713 30.5944C46.2979 27.1697 45.9216 23.5845 44.399 20.379C42.8765 17.1734 40.2902 14.5212 37.0299 12.822Z"
      fill="#F03864"
    />
  </svg>
);

export default ReloadIcon;
