import styled from 'styled-components';
import getTokenValues from 'core/utils/getTokensValues';

const iconPadding = 4;
const InputSpan = styled.span`
  color: #667085;
margin-left: 10px;
padding: ${iconPadding}px;
position: absolute;
box-sizing:border-box;
top:50%;
left:2px;
transform: translateY(-50%);
`;

const InputDiv = styled.div`
position: relative;
display: flex;
align-items: center;
  width: 364px;
  height: 64px;
`;

const InputButton = styled.button`
margin-left: 10px;
padding: ${iconPadding}px;
position: absolute;
box-sizing:border-box;
top:50%;
left:2px;
transform: translateY(-50%);
background-color: transparent;
border: 0px;
`;

interface StyledInputProps {
  error?:boolean
  textPadding?: boolean
}

const StyledInput = styled.input<StyledInputProps>`
  box-sizing: border-box;
  padding: 18px 18px 18px ${({ textPadding }) => textPadding && '60px'};
  border-radius: 10px;
  border: 0;
  background-color: rgba(249, 250, 251, 0.8);
  width: 364px;
  height: 64px;
  font-weight: 400;
  font-size: 16px;
  &:focus {
    outline: none;
    border: 3px solid ${getTokenValues('700')};
    border-radius: 10px;
  }
`;

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean,
  icon?: React.ReactNode | undefined,
  onIconClick?: Function | undefined,
}

const Input = ({
  error,
  icon,
  onIconClick,
  ...rest
} : InputProps) => (
  <InputDiv>
    { onIconClick ? (
      <InputButton onClick={() => onIconClick()} type="button">
        {icon}
      </InputButton>
    ) : (
      <InputSpan>
        {icon}
      </InputSpan>
    )}
    {onIconClick || icon ? (
      <StyledInput
        error={error}
        textPadding
        {...rest}
      />
    ) : (
      <StyledInput
        error={error}
        {...rest}
      />
    )}
  </InputDiv>
);
export default Input;

Input.defaultProps = {
  error: false,
  icon: undefined,
  onIconClick: undefined,
};
