class SpinnerClass {
  spinnerNode: HTMLDivElement = document.createElement('div');

  constructor() {
    this.spinnerNode.className = 'bury-spinner';
    this.spinnerNode.id = 'bury-spinner-id';
  }

  show() {
    const body: HTMLBodyElement = document.getElementsByTagName('body')[0];
    body.appendChild(this.spinnerNode);
  }

  hide() {
    this.spinnerNode.remove();
  }
}
const BurySpinner = new SpinnerClass();
export { SpinnerClass, BurySpinner };
