import Typography from '../../../shared/components/Typography';

export default function SetMediaDescriptionTexts({ contentType }: { contentType: 'video' | 'image' }) {
  return (
    <div>
      {contentType === 'video' && (
        <Typography variant="text-04-medium" color="500" className="mt-7">
          Resolución mínima: 1280 x 720 píxeles
        </Typography>
      )}
      {contentType === 'video' && (
        <Typography variant="text-04-medium" color="500" className="mt-7">
          Peso máximo de video: 250 MB
        </Typography>
      )}
      {contentType === 'image' && (
        <Typography variant="text-04-medium" color="500" className="mt-7">
          Tamaño: 1500x500 píxeles
        </Typography>
      )}
      {contentType === 'image' && (
        <Typography variant="text-04-medium" color="500" className="mt-7">
          Formato: JPG O PNG, RGB Colors
        </Typography>
      )}
      {contentType === 'image' && (
        <Typography variant="text-04-medium" color="500" className="mt-7">
          Peso máximo permitido: 250 MB
        </Typography>
      )}
    </div>
  );
}
