import styled from 'styled-components';
import { FiX } from 'react-icons/fi';

interface Props {
  children: JSX.Element,
  width?: string,
  height?: string,
  maxWidth?: string,
  maxHeight?: string,
  closeIconAction?: Function,
}
const ModalStyles = styled.div<{
  width?: string,
  height?: string,
  maxWidth?: string,
  maxHeight?: string,
  closeIconAction?: Function }>`
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  & .modal-block{
    position: relative;
    box-sizing: border-box;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    max-height: ${(props) => props.maxHeight};
    max-width: ${(props) => props.maxWidth};
    background-color: white;
    border: 1px solid #667085;
    border-radius: 5.85882px;
  }
  & .close-icon{
    position: absolute;
    top: 12px;
    right: 12px;
  }
`;

const Wrapper = ({
  children, width, height, maxWidth, maxHeight, closeIconAction,
}: Props) => (
  <ModalStyles
    width={width}
    height={height}
    maxWidth={maxWidth ?? ''}
    maxHeight={maxHeight ?? ''}
    aria-hidden="true"
    onClick={() => closeIconAction?.()}
  >
    {children}
  </ModalStyles>
);

export default function Modal({
  children, height, width, maxWidth, maxHeight, showCloseIcon, closeIconAction,
}: Props & { showCloseIcon?: boolean, closeIconAction?: Function }) {
  return (
    <Wrapper
      width={width}
      height={height}
      maxWidth={maxWidth ?? ''}
      maxHeight={maxHeight ?? ''}
      closeIconAction={closeIconAction}
      aria-hidden="true"
    >
      <div className="modal-block">
        {showCloseIcon && (
          <button type="button" onClick={() => closeIconAction}>
            <FiX className="close-icon cursor-pointer" size="24px" />
          </button>
        )}
        { children }
      </div>
    </Wrapper>
  );
}

Wrapper.defaultProps = {
  height: '180px',
  width: '260px',
  maxWidth: undefined,
  maxHeight: undefined,
  closeIconAction: () => {},
};
Modal.defaultProps = {
  height: '180px',
  width: '260px',
  maxWidth: undefined,
  maxHeight: undefined,
  showCloseIcon: false,
  closeIconAction: undefined,
};
