import styled from 'styled-components';
import { FiLayers, FiUser } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'routes/routes';
import useAuth from 'hooks/useAuth';
import SidebarItem from 'shared/components/SidebarItem';
import SLPLogoImage from 'public/slp-logo.png';
import getColorFamilies from 'core/utils/getColorTokens';

type DashboardSideBarProps = {
  activeView: string;
};

const DashboardSidebar = ({ activeView }: DashboardSideBarProps) => {
  const authUser = useAuth();
  const redirect = useNavigate();
  const { removeUserData } = authUser;

  const handleLogOut = () => {
    if (removeUserData) {
      removeUserData();
      redirect(ROUTES.HOME);
    }
  };

  return (
    <SidebarContainer>
      <img src={SLPLogoImage} alt="logo" className="logo-image" />
      <SidebarItem
        extraClass={activeView === ROUTES.CONTENT_DESIGN ? 'active' : ''}
        onClickAction={() => redirect(ROUTES.CONTENT_DESIGN)}
        text="Diseño y contenido"
        icon={<FiLayers color="white" size="24px" />}
      />
      <SidebarItem
        extraClass={activeView === ROUTES.USERS ? 'active' : ''}
        onClickAction={() => redirect(ROUTES.USERS)}
        text="Usuarios"
        icon={<FiUser color="white" size="24px" />}
      />
      <SidebarItem
        onClickAction={handleLogOut}
        text="Salir"
        icon={<FiUser color="white" size="24px" />}
        extraClass="user-icon"
      />
    </SidebarContainer>
  );
};

export default DashboardSidebar;

const SidebarContainer = styled.aside`
  background-color: ${getColorFamilies('alias-background-primary')};
  height: 100%;
  width: 250px;
  position: relative;
  & .user-icon {
    position: absolute;
    bottom: 59px;
  }
  & .logo-image {
    margin: 24px 26px;
  }
`;
