import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ROUTES from 'routes/routes';

import ContentDesign from 'features/admin/dashboard';
import Login from 'features/admin/Login';
import Users from 'features/admin/dashboard/UsersView';
import NotFound from 'shared/components/NotFound';

import RestrictedRoute from './utils/restricted-route';
import SecureRedirect from './utils/secure-redirect';
import UnauthenticatedRoute from './utils/unauthenticated-route';

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route
        path={ROUTES.CONTENT_DESIGN}
        element={<RestrictedRoute component={ContentDesign} />}
      />
      <Route
        path={ROUTES.USERS}
        element={<RestrictedRoute component={Users} />}
      />
      <Route
        path={ROUTES.HOME}
        element={<SecureRedirect to={ROUTES.CONTENT_DESIGN} />}
      />
      <Route
        path={ROUTES.LOGIN}
        element={<UnauthenticatedRoute component={Login} to={ROUTES.CONTENT_DESIGN} />}
      />
      <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);

export default Router;
