import { AiOutlineQuestionCircle } from 'react-icons/ai';
import styled from 'styled-components';
import UIButton from 'core/ui/UIButton';
import Modal from './Modal';
import Typography from './Typography';

const ConfirmationModalBlockStyles = styled.div`
  padding: 20px;
  & .message{
    -webkit-line-clamp: 2;
    width: 100%;
    text-align: center;
  }
`;
interface Props {
  message: string
  confirmationAction: { () : void }
  declineAction: { (): void },
  confirmationButtonText?: string
  declineButtonText?: string
}
export default function ConfirmationModal({
  message, confirmationAction, declineAction, confirmationButtonText, declineButtonText,
}: Props) {
  return (
    <Modal>
      <ConfirmationModalBlockStyles className="full-height full-width border-box flex column-direction justify-content-between align-items-center">
        <AiOutlineQuestionCircle size="22px" />
        <Typography variant="subtitle-04-semibold" className="truncate-multi-line message">
          {message}
        </Typography>
        <div className="total-center gap-md">
          <UIButton width="85px" height="35px" borderRadius="4px" text={declineButtonText} onClick={declineAction} variant="secondary" />
          <UIButton width="85px" height="35px" borderRadius="4px" text={confirmationButtonText} onClick={confirmationAction} />
        </div>
      </ConfirmationModalBlockStyles>
    </Modal>
  );
}

ConfirmationModal.defaultProps = {
  confirmationButtonText: 'Continuar',
  declineButtonText: 'Cancelar',
};
