import styled from 'styled-components';
import Button from 'shared/components/Button';
import Typography from 'shared/components/Typography';
import getColorFamilies from 'core/utils/getColorTokens';
import ConfirmationModal from 'shared/components/ConfirmationModal';
import { useState, useContext, useMemo } from 'react';
import { updateBanner, changeColor, updateVideo } from 'services';
import SuccessModal from 'shared/components/SucessModal';
import SetMediaContentComponent from './SetMediaContentComponent';
import SetVideoContentComponent from './SetVideoContentComponent';
import ContentColorSelector from './ContentColorSelector';
import Store from './dashboardStore';
import { ActionTypes } from './dashboardReducer';
import { BurySpinner } from '../../../shared/BurySpinner';
import beforeUnloadCallback from './beforeUnloadCallback';

const SetContentSectionStyles = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr;
  & .title-section {
    background-color: ${getColorFamilies('alias-background-primary')};
    padding-left: 23px;
  }
  .content-section {
    padding: 0 12px;
    overflow: auto;
    height: 80vh;
    ::-webkit-scrollbar { width: 8px; }
    ::-webkit-scrollbar-track { display: none; }
    ::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      border-radius: 4px;
    }
  }
`;

type FieldName = 'video' | 'image1' | 'image2' | 'image3';

export default function DashboardSetContentSection(): JSX.Element {
  const [showPublishModal, setShowPublishModal] = useState(false);
  const { state, dispatch } = useContext(Store);
  const {
    banner1,
    banner2,
    banner3,
    color,
    secondaryColor,
    success,
    video,
  } = state;
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: FieldName,
  ) => {
    const {
      target: { name, value },
    } = event;
    const newState = {
      ...state,
      [fieldName]: {
        ...state[fieldName],
        [name]: value,
        updatedAt: new Date(),
      },
    };

    dispatch({ type: ActionTypes.UPDATE_DASHBOARD, payload: newState });
  };

  const handleMainColor = (newColor: string) => {
    dispatch({
      type: ActionTypes.UPDATE_DASHBOARD,
      payload: { color: newColor, updatedAt: new Date() },
    });
  };

  const handleSecondaryColor = (newColor: string) => {
    dispatch({
      type: ActionTypes.UPDATE_DASHBOARD,
      payload: { secondaryColor: newColor },
    });
  };

  const setVideoFile = (videoFile: File) => {
    dispatch({ type: ActionTypes.LOAD_VIDEO_FILE, payload: videoFile });
  };
  const setVideoUrl = (videoLocalURL: string) => {
    dispatch({ type: ActionTypes.LOAD_VIDEO_URL, payload: videoLocalURL });
  };

  const showSuccess = () => {
    dispatch({ type: ActionTypes.SET_SUCCESS, payload: true });
    setTimeout(() => dispatch({ type: ActionTypes.SET_SUCCESS, payload: false }), 2000);
  };

  const closeSuccessModal = () => dispatch({ type: ActionTypes.SET_SUCCESS, payload: false });

  // eslint-disable-next-line max-len
  const canUpdateBanners = useMemo<boolean>(
    () => // eslint-disable-next-line max-len,implicit-arrow-linebreak
      /^(https?:\/\/)[\w-]+(\.[\w-]+)+([/\w.,@?^=%&:~+#-]*)?$/g.test(banner1.link) && /^(https?:\/\/)[\w-]+(\.[\w-]+)+([/\w.,@?^=%&:~+#-]*)?$/g.test(banner2.link) && /^(https?:\/\/)[\w-]+(\.[\w-]+)+([/\w.,@?^=%&:~+#-]*)?$/g.test(banner3.link),
    [banner1, banner2, banner3],
  );

  const publish = () => {
    BurySpinner.show();
    window.addEventListener('beforeunload', beforeUnloadCallback);
    dispatch({ type: ActionTypes.SET_LOADING, payload: true });
    const dataToUpload = [
      updateBanner({ ...banner1, bannerType: banner1.name }),
      updateBanner({ ...banner2, bannerType: banner2.name }),
      updateBanner({ ...banner3, bannerType: banner3.name }),
      changeColor('primary', { color }),
      changeColor('secondary', { color: secondaryColor }),
      video.videoFile ? updateVideo({ video: video.videoFile }) : null,
    ];

    Promise.all(dataToUpload).then(() => {
      dispatch({ type: ActionTypes.SET_LOADING, payload: false });
      showSuccess();
      window.removeEventListener('beforeunload', beforeUnloadCallback);
      BurySpinner.hide();
    }).finally(() => {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
      BurySpinner.hide();
      setShowPublishModal(false);
    });
  };

  return (
    <SetContentSectionStyles>
      <div className="title-section text-left flex align-items-center">
        <Typography variant="subtitle-04-semibold" color="100">
          Zona de contenido
        </Typography>
      </div>
      <div className="content-section">
        {showPublishModal && (
          <ConfirmationModal
            message="¿Deseas publicar los cambios?"
            confirmationAction={publish}
            declineAction={() => setShowPublishModal(false)}
          />
        )}
        <SetVideoContentComponent
          title="Video promo banner"
          setVideoFile={setVideoFile}
          setVideoUrl={setVideoUrl}
        />
        <SetMediaContentComponent
          contentType="image"
          title="Imagen publicitaria #1"
          state={banner1}
          handleChange={handleChange}
        />
        <SetMediaContentComponent
          contentType="image"
          title="Imagen publicitaria #2"
          state={banner2}
          handleChange={handleChange}
        />
        <SetMediaContentComponent
          contentType="image"
          title="Imagen publicitaria #3"
          state={banner3}
          handleChange={handleChange}
        />
        <ContentColorSelector
          setMain={handleMainColor}
          setSecondary={handleSecondaryColor}
          mainColor={color}
          secondaryColor={secondaryColor}
        />
        <div className="mb-2 mt-1">
          <Button
            disabled={!canUpdateBanners}
            onClick={() => setShowPublishModal(true)}
          >
            Publicar
          </Button>
          {success && (
            <SuccessModal caption="¡Publicado con exito!" onClose={closeSuccessModal} />
          )}
        </div>
      </div>
    </SetContentSectionStyles>
  );
}
