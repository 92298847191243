import React from 'react';
import { FaCheck } from 'react-icons/fa';
import styled from 'styled-components';

const colors: string[] = [
  '#333333',
  '#4F4F4F',
  '#828282',
  '#BDBDBD',
  '#E0E0E0',
  '#F2F2F2',
  '#EB5757',
  '#F2994A',
  '#F2C94C',
  '#219653',
  '#27AE60',
  '#6FCF97',
  '#2F80ED',
  '#2D9CDB',
  '#56CCF2',
  '#9B51E0',
  '#BB6BD9',
];

type ColorSelectorProps = {
  selectedColor: string,
  onChange: Function,
};
const ColorSelector: React.FC<ColorSelectorProps> = ({ selectedColor, onChange }) => (
  <Content className=" flex row-direction">

    <input
      type="color"
      value={selectedColor}
      className="color-block  total-center"
      onChange={(e) => onChange(e.target.value)}
    />

    {colors.map((color) => (
      <button
        type="button"
        className="color-block total-center"
        style={{ backgroundColor: color }}
        key={color}
        onClick={() => onChange(color)}
      >
        {selectedColor === color && <FaCheck color="white" size="13px" />}
      </button>
    ))}

  </Content>
);

export default ColorSelector;

const Content = styled.div`

    flex-wrap: wrap;
    gap: 13px;
    padding: 8px;
  
  & .color-block {
    width: 25px;
    height: 25px;
    border-radius: 3px;
    outline: none;
    border: none;
  }
  & .mt-16 {
    margin-top: 17px;
  }
`;
