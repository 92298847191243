import useAuth from 'hooks/useAuth';
import { JSXElementConstructor } from 'react';
import { Navigate } from 'react-router-dom';

type UnauthenticatedRouteProps = {
  component: JSXElementConstructor<unknown>;
  to: string;
  [x: string]: unknown;
};

const UnauthenticatedRoute = ({
  component: Component,
  to,
  ...rest
}: UnauthenticatedRouteProps) => {
  const { getIsLoggedIn } = useAuth();

  const isLoggedIn = getIsLoggedIn();

  return !isLoggedIn ? (
    <Component {...rest} />
  ) : (
    <Navigate to={to} replace />
  );
};

export default UnauthenticatedRoute;
