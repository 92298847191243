import Axios, { AxiosRequestConfig } from 'axios';

export const AXIOS_INSTANCE = Axios.create({ baseURL: process.env.REACT_APP_SLP_API_URL || 'https://api-portal.sanluiscapital.gob.mx/api' });

export const axiosInstance = <T>(axiosConfig: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const token = JSON.parse(localStorage.getItem('token') as string);
  AXIOS_INSTANCE.defaults.headers.common.Authorization = `Baerer ${token}`;

  const promise = AXIOS_INSTANCE({
    ...axiosConfig,
    cancelToken: source.token,
  }).then(
    ({ data }) => data,
  );

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};
export default axiosInstance;
