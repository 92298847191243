import React, { useMemo } from 'react';
import styled from 'styled-components';
import getColorFamilies from '../utils/getColorTokens';

interface Props {
  width?: string,
  height?: string,
  variant?: 'primary' | 'secondary' | 'disabled',
  text?: string
  borderRadius?: string
  onClick?: { (e?: any) :void }
  type?: 'button' | 'reset' | 'submit',
  extraClass?: string
}

const UIButtonStyles = styled.button<Props>`
  box-sizing: border-box;
  padding: 0 12px;
  width: ${(props) => props.width ?? ''};
  height: ${(props) => props.height ?? ''};
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  border-radius: ${(props) => props.borderRadius};
  outline: none;
  border: none;
  &:hover{ opacity: 0.8; }
  &.primary{
    background-color: ${getColorFamilies('alias-button-primary')};
    color: ${getColorFamilies('alias-text-tertiary')};
  }
  &.secondary{
    background-color: ${getColorFamilies('alias-button-secondary')};
    color: ${getColorFamilies('alias-text-secondary')};
  }
  &.disabled{
    background-color: ${getColorFamilies('alias-button-secondary')};
    color: ${getColorFamilies('alias-text-secondary')};
    pointer-events: none;
  }
`;

const UIButton: React.FC<Props> = ({
  variant = 'primary', height = '64px', width = '327px', text = '',
  borderRadius = '16px', onClick = () => {}, type = 'button', extraClass = '',
}) => {
  const computedEvent = useMemo(() => {
    if (variant === 'disabled') {
      return () => {};
    }
    return onClick;
  }, [variant, onClick]);

  return (
    <UIButtonStyles
      type={type}
      height={height}
      width={width}
      className={`${variant} truncate cursor-pointer ${extraClass}`}
      title={text}
      borderRadius={borderRadius}
      onClick={computedEvent}
    >
      {text}
    </UIButtonStyles>
  );
};
export default UIButton;
