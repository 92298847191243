import { JSXElementConstructor } from 'react';

import useAuth from 'hooks/useAuth';
import { Navigate } from 'react-router-dom';
import ROUTES from 'routes/routes';

type RouteProps<T> = T extends any ? T : never;

type RestrictedRouteProps<T = any> = {
  component: JSXElementConstructor<RouteProps<T>>;
  [x: string]: unknown;
};

const RestrictedRoute = ({
  component: Component,
  ...rest
}: RestrictedRouteProps) => {
  const { getIsLoggedIn } = useAuth();

  const isLoggedIn = getIsLoggedIn();

  return isLoggedIn ? (
    <Component {...rest} />
  ) : (
    <Navigate to={ROUTES.LOGIN} />
  );
};

export default RestrictedRoute;
